<!-- banner -->
<template>
  <!-- 顶部图片or轮播图 -->
  <div class="home-banner">
    <div class="carousel">
      <video class="video" autoplay="autoplay" muted="muted" loop="loop" src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/home/cloud_750.mp4" />
      <div class="content">
        <div class="box">
          <div class="title">
            <img src="@/assets/home/JiaFuYunLogo.png" alt="">
          </div>
          <div class="text">嘉服云（JiaCloud）是中嘉数科旗下数字化产品服务生态体系。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeBanner'
};
</script>

<style lang='scss' scoped>
.home-banner {
  height: 650px;
  .carousel {
    width: 100%;
    height: 100%;
    position: relative;
    .content {
      position: absolute;
      top: 200px;
      left: 0;
      width: 100%;
      .box {
        width: 1200px;
        margin: 0 auto;
      }
      .title {
        width: 209px;
        height: 61px;
        // font-size: 40px;
        // font-weight: 700;
        margin-bottom: 40px;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 32px;
      }
    }
    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  /deep/ .el-carousel__container {
    height: 650px;
  }
}
</style>
