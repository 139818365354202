<!-- 我们的优势 -->
<template>
  <div class="home-advantage">
    <!-- 标题 -->
    <div class="home-advantage-title">
      <span>我们的优势</span>
    </div>
    <div class="home-advantage-content">
      <!-- 优势列表 -->
      <div class="home-advantage-list">
        <div v-for="(item, index) in advantageList" :key="index" class="home-advantage-item">
          <div class="home-advantage-item-title">{{ item.title }}</div>
          <div class="home-advantage-item-tip">{{ item.tip }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAdvantage',
  data() {
    return {
      advantageList: [
        {
          title: '生态',
          tip: 'JCD不想做独吞的角色，而是连接和支撑生态链的使者，在充分吸收先行者的成功经验和自身数字化实践积累下，致力于和客户伙伴联合创新，和数字化共建者们一起沉淀。'
        }, {
          title: '融合',
          tip: 'JCD以物联网为核心ICT技术、区别于以设备管理为主的主流物联网平台，AllOne IoT和第三方平台管理模块加大了对客户伙伴的南向开放度，更大程度融合生态中各方的数据和业务。'
        }, {
          title: '开放',
          tip: '组件化、模块化、多环境部署能力和集成客户伙伴的平台能力是JCD对客户伙伴开放度的保证，除此之外JCD正在筹划的“嘉园开发者计划”，将邀广大开发者共建生态。'
        }, {
          title: '高效',
          tip: '中嘉覆盖“咨询设计、研发集成、工程实施、运维运营”全生命周期产品和服务的生态能力，以及JCD预集成的平台综合能力，支持项目高效且高质量交付。'
        }
      ]
    };
  }
};
</script>

<style lang='scss' scoped>
.home-advantage {
  width: 100%;
  height: 662px;
  background: #ffffff;
  padding: 76px 0;
  .home-advantage-title {
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    // font-family: 'PingFang-Medium'!important;
    text-align: center;
    color: #333333;
    margin-bottom: 49px;
  }
  .home-advantage-list {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .home-advantage-item {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      width: 540px;
      height: 215px;
      padding: 62px 37px 42px 30px;
      background-image: url('~@/assets/img/advantage-background.png');
      background-size: 100% 100%;
      &:nth-child(2) {
        background-image: url('~@/assets/img/advantage-background2.png');
      }
      &:nth-child(3) {
        background-image: url('~@/assets/img/advantage-background3.png');
      }
      &:nth-child(4) {
        background-image: url('~@/assets/img/advantage-background4.png');
      }
      &:hover {
        .home-advantage-item-title {
          color: #2469f1;
        }
      }
      // 选中前两个元素
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 8px;
      }
      .home-advantage-item-title {
        height: 25px;
        line-height: 25px;
        font-size: 18px;
        // font-family: 'PingFang-Medium'!important;
        color: #333333;
      }
      .home-advantage-item-tip {
        line-height: 24px;
        font-size: 14px;
        // font-family: 'pingfang-Regular';
        text-align: justify;
        color: #666666;
      }
    }
  }
}
</style>
