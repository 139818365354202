// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/home-ours.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".home-ours[data-v-40a035da]{width:100%;height:301px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50% 50%}.home-ours .home-ours-content[data-v-40a035da]{width:1200px;height:100%;margin:0 auto;padding:71px 0 107px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.home-ours .home-ours-content .home-ours-title[data-v-40a035da]{height:40px;line-height:40px;font-size:28px;color:#fff}.home-ours .home-ours-content .home-ours-btn-group[data-v-40a035da]{display:-webkit-box;display:-ms-flexbox;display:flex}.home-ours .home-ours-content .home-ours-btn-group .home-ours-btn[data-v-40a035da]{cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:154px;height:44px;line-height:44px;text-align:center;font-size:18px;background:#fff;border-radius:8px;color:#09273a;margin-right:18px}.home-ours .home-ours-content .home-ours-btn-group .home-ours-btn[data-v-40a035da]:hover{-webkit-box-shadow:0 8px 14px 0 rgba(39,99,229,.53);box-shadow:0 8px 14px 0 rgba(39,99,229,.53)}", ""]);
// Exports
module.exports = exports;
