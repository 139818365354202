<!-- 行业生态伙伴 -->
<template>
  <div class="home-ecosystem-partner">
    <!-- 标题 -->
    <div class="home-ecosystem-partner-title">
      <span>行业生态伙伴</span>
    </div>
    <!-- 内容 -->
    <div class="home-ecosystem-partner-content">
      <img src="@/assets/img/ecosystem-partner-img.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeEcosystemPartner'
};
</script>

<style lang='scss' scoped>
.home-ecosystem-partner {
  width: 100%;
  height: 867px;
  background: #ffffff;
  padding: 76px 0 57px 0;
  .home-ecosystem-partner-title {
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    // font-family: 'PingFang-Medium'!important;
    text-align: center;
    color: #333333;
    margin-bottom: 49px;
  }
  .home-ecosystem-partner-content {
    width: 1216px;
    height: 646px;
    margin: 0 auto;
  }
}
</style>
