var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-news"},[_c('div',{staticClass:"home-news-title"},[_c('span',[_vm._v("新闻资讯")]),_vm._v(" "),_c('div',{staticClass:"home-news-title-more-btn",on:{"click":_vm.goNews}},[_vm._v("\n      了解更多内容\n      "),_c('img',{attrs:{"src":require("@/assets/img/more-arrow.png"),"alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"home-news-carousel"},[_c('div',{staticClass:"carousel-container",attrs:{"id":"carousel-container"}},[_c('div',{staticClass:"carousel-wrapper",style:({
          transform: ("translateX(-" + ((_vm.currentItem + _vm.newsList.length) * 598) + "px)"),
          transition: ("transform " + _vm.transitionTime + "s")
        }),attrs:{"id":"carousel-wrapper"}},[_vm._l((_vm.newsList),function(item,index){return _c('div',{key:("carousel-item1" + index),staticClass:"carousel-item",style:({
            opacity: item.opacity
          })},[_c('div',{staticClass:"carousel-item-content"},[_c('div',{staticClass:"carousel-item-title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),_c('div',{staticClass:"carousel-item-desction"},[_vm._v("\n              "+_vm._s(item.desction)+"\n            ")])])])}),_vm._v(" "),_vm._l((_vm.newsList),function(item,index){return _c('div',{key:("carousel-item" + index),staticClass:"carousel-item",style:({
            opacity: item.opacity
          })},[_c('div',{staticClass:"carousel-item-content"},[_c('div',{staticClass:"carousel-item-title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),_c('div',{staticClass:"carousel-item-desction"},[_vm._v("\n              "+_vm._s(item.desction)+"\n            ")])])])}),_vm._v(" "),_vm._l((_vm.newsList),function(item,index){return _c('div',{key:("carousel-item2" + index),staticClass:"carousel-item",style:({
            opacity: item.opacity
          })},[_c('div',{staticClass:"carousel-item-content"},[_c('div',{staticClass:"carousel-item-title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),_c('div',{staticClass:"carousel-item-desction"},[_vm._v("\n              "+_vm._s(item.desction)+"\n            ")])])])})],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newsList.length),expression:"newsList.length"}],staticClass:"carousel-btn-wrapper"},[_c('div',{staticClass:"carousel-btn",on:{"click":_vm.prev}}),_vm._v(" "),_c('div',{staticClass:"carousel-btn",on:{"click":_vm.next}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }