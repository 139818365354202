<!-- 新闻资讯 -->
<template>
  <div class="home-news">
    <!-- 标题 -->
    <div class="home-news-title">
      <span>新闻资讯</span>
      <div class="home-news-title-more-btn" @click="goNews">
        了解更多内容
        <img src="@/assets/img/more-arrow.png" alt="">
      </div>
    </div>
    <!-- 新闻资讯轮播图 -->
    <div class="home-news-carousel">
      <div id="carousel-container" class="carousel-container">
        <div
          id="carousel-wrapper"
          class="carousel-wrapper"
          :style="{
            transform: `translateX(-${(currentItem + newsList.length) * 598}px)`,
            transition: `transform ${transitionTime}s`
          }"
        >
          <!-- 前补位 -->
          <div
            v-for="(item, index) in newsList"
            :key="`carousel-item1${index}`"
            class="carousel-item"
            :style="{
              opacity: item.opacity
            }"
          >
            <div class="carousel-item-content">
              <!-- 标题 -->
              <div class="carousel-item-title">
                <span>{{ item.title }}</span>
              </div>
              <!-- 内容 -->
              <div class="carousel-item-desction">
                {{ item.desction }}
              </div>
            </div>
          </div>
          <!-- 本体 -->
          <div
            v-for="(item, index) in newsList"
            :key="`carousel-item${index}`"
            class="carousel-item"
            :style="{
              opacity: item.opacity
            }"
          >
            <div class="carousel-item-content">
              <!-- 标题 -->
              <div class="carousel-item-title">
                <span>{{ item.title }}</span>
              </div>
              <!-- 内容 -->
              <div class="carousel-item-desction">
                {{ item.desction }}
              </div>
            </div>
          </div>
          <!-- 后补位 -->
          <div
            v-for="(item, index) in newsList"
            :key="`carousel-item2${index}`"
            class="carousel-item"
            :style="{
              opacity: item.opacity
            }"
          >
            <div class="carousel-item-content">
              <!-- 标题 -->
              <div class="carousel-item-title">
                <span>{{ item.title }}</span>
              </div>
              <!-- 内容 -->
              <div class="carousel-item-desction">
                {{ item.desction }}
              </div>
            </div>
          </div>
        </div>
        <!-- 切换按钮区域 -->
        <div v-show="newsList.length" class="carousel-btn-wrapper">
          <div class="carousel-btn" @click="prev" />
          <div class="carousel-btn" @click="next" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeNews',
  props: {
    newsList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      timer: null,
      currentItem: 0,
      transitionTime: 0.2
    };
  },
  mounted() {
    const io = new IntersectionObserver((data) => {
      console.log('进入视口', data);
    });
    io.observe(document.getElementById('carousel-container'));
  },
  methods: {
    // 向前滚动一位
    next() {
      // 防抖处理：如果在350ms内再次触发，则不执行
      if (this.timer && Date.now() - this.timer < 350) {
        return;
      }
      this.timer = Date.now(); // 更新时间戳

      if (this.currentItem < this.newsList.length - 1) {
        this.currentItem++;
      } else {
        this.currentItem++;
        this.$nextTick(async() => {
          await setTimeout(() => {
            this.transitionTime = 0;
            this.currentItem = 0; // 如果是最后一项，则滚动到第一项
          }, 250);
          await setTimeout(() => {
            this.transitionTime = 0.2;
          }, 300);
        });
      }
    },
    prev() {
      // 防抖处理：如果在350ms内再次触发，则不执行
      if (this.timer && Date.now() - this.timer < 350) {
        return;
      }
      this.timer = Date.now(); // 更新时间戳

      if (this.currentItem > 0) {
        this.currentItem--;
      } else {
        this.currentItem--;
        this.$nextTick(async() => {
          await setTimeout(() => {
            this.transitionTime = 0;
            this.currentItem = this.newsList.length - 1;
          }, 250);
          await setTimeout(() => {
            this.transitionTime = 0.2;
          }, 300);
        });
      }
    },
    goNews() {
      this.$router.push('/news?type=0&id=33');
    }
  }
};
</script>

<style lang='scss' scoped>
.home-news {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 580px;
  background-image: url(~@/assets/img/news-bg.png), linear-gradient(206deg,#1d2437 0%, #1d2437 84%);
  background-size: contain;
  background-repeat: no-repeat;
  padding: 76px 0 72px 0;
  .home-news-title {
    position: relative;
    width: 1200px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    font-size: 28px;
    // font-family: 'PingFang-Medium'!important;
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px;
    .home-news-title-more-btn {
      cursor: pointer;
      user-select: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 16px;
      border-radius: 8px;
      background: #2071f3;
      img {
        width: 7px;
        height: 10px;
      }
      &:hover {
        background: #4990ff;
      }
    }
  }
  .home-news-carousel {
    width: 100%;
    flex: 1;
    padding-left: 436px;
    .carousel-container {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      .carousel-wrapper {
        display: flex;
        .carousel-item {
          width: auto;
          flex-shrink: 0;
          padding-right: 60px;
          transition: all 0.2s;
          opacity: 1;
          .carousel-item-content {
            width: 538px;
            height: 238px;
            text-align: center;
            background: #ffffff;
            border-radius: 8px;
            padding: 42px 46px 0 28px;
            .carousel-item-title {
              height: 44px;
              line-height: 22px;
              font-size: 16px;
              // font-family: 'PingFang-Medium'!important;
              text-align: left;
              color: #333333;
              margin-bottom: 32px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .carousel-item-desction {
              height: 72px;
              line-height: 24px;
              font-size: 14px;
              // font-family: 'pingfang-Regular';
              text-align: justify;
              color: #666666;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          // 进入
          &.enter {
            opacity: 0;
          }
          &.leave {
            opacity: 0;
          }
        }
      }
      .carousel-btn-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 61px;
        transform: translateX(-218px);
        .carousel-btn {
          cursor: pointer;
          width: 43px;
          height: 43px;
          background: url(~@/assets/img/carousel-btn.png);
          background-size: 100% 100%;
          &:nth-child(1) {
            transform: rotate(180deg);
          }
          &:nth-child(2) {
            margin-left: 26px;
          }
          &:hover {
            background: url(~@/assets/img/carousel-btn-hover.png);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
