<!-- 首页 -->
<template>
  <div class="wrapper pageBox">
    <!-- 顶部图片or轮播图 -->
    <HomeBanner id="HomeBanner" ref="HomeBanner" />
    <!-- 导航条 -->
    <NavBar id="NavBar" ref="NavBar" :nav-list="navList" />
    <!-- 嘉服云生态体系 -->
    <HomeEcosystem id="ecosystem_div" ref="HomeEcosystem" />
    <!-- 我们的优势 -->
    <HomeAdvantage id="advantage_div" ref="HomeAdvantage" />
    <!-- 方案介绍 -->
    <HomeIntroduce id="introduce_div" ref="HomeIntroduce" />
    <!-- 行业生态伙伴 -->
    <HomeEcosystemPartner id="ecosystem_partner_div" ref="HomeEcosystemPartner" />
    <!-- 新闻资讯 -->
    <HomeNews id="news_div" ref="HomeNews" :news-list="newsList" />
    <!-- 加入我们 -->
    <HomeOurs id="ours_div" ref="HomeOurs" />
  </div>
</template>

<script>
import NavBar from '@/components/navBar/index';
import HomeEcosystem from './components/ecosystem';
import HomeAdvantage from './components/advantage';
import HomeIntroduce from './components/introduce';
import HomeEcosystemPartner from './components/ecosystemPartner';
import HomeBanner from './components/banner';
import HomeNews from './components/news.vue';
import HomeOurs from './components/ours.vue';
import { getHomeNews } from '@/service/api';
export default {
  name: 'Home',
  components: { NavBar, HomeEcosystem, HomeAdvantage, HomeIntroduce, HomeEcosystemPartner, HomeBanner, HomeNews, HomeOurs },
  data() {
    return {
      newsList: [], // 新闻列表
      navList: [
        { name: '生态体系', id: 'ecosystem_div' },
        { name: '独特优势', id: 'advantage_div' },
        { name: '方案介绍', id: 'introduce_div' },
        { name: '生态伙伴', id: 'ecosystem_partner_div' },
        { name: '新闻资讯', id: 'news_div' }
      ] // 导航栏列表
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getHomeNews();
      if (res.code === 200) {
        this.newsList = res.data;
      }
    }
  }
};
</script>

<style lang='scss' scoped>

</style>
