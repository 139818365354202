<template>
  <!-- <transition name="fade"> -->
  <div class="nav-bar-wrapper">
    <div id="navBar" class="nav-bar" :class="{ 'is-active': activeClass }">
      <div class="content-box flex-space-between">
        <a
          v-for="(item, index) in navList"
          :key="`nav-item${index}`"
          class="nav-item"
          :class="{ 'is-active': activeIndex === index }"
          @click="onClick(item, index)"
        >{{ item.name }}</a>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      headHeight: 0,
      activeIndex: 0,
      activeClass: false
    };
  },
  mounted() {
    this.updateHeadHeight();
    window.addEventListener('scroll', this.handleScroll, true);
  },
  beforeDestroy() {
    // 在组件销毁之前解绑事件，防止内存泄漏
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    /**
     * 更新页面头部高度
     * 在组件渲染完成后，通过DOM操作获取首页横幅的高度，并设置为组件数据中的头部高度
     */
    updateHeadHeight() {
      this.$nextTick(() => {
        // 如果已经缓存了homeBanner，就不需要再次查询DOM
        this.homeBanner = this.homeBanner || document.getElementById('HomeBanner');
        this.headHeight = this.homeBanner ? this.homeBanner.offsetHeight : 0;
      });
    },

    /**
     * 处理页面滚动事件
     * 监听页面滚动，根据滚动位置决定是否应用激活状态，并更新基于滚动的活动索引
     */
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.activeClass = scrollTop >= this.headHeight; // 判断是否滚动超过头部高度，以决定是否应用激活状态
      this.updateActiveIndexBasedOnScroll(scrollTop); // 更新基于滚动的活动索引
    },

    /**
     * 点击导航项时的处理函数
     * @param {number} index - 被点击的导航项索引
     * 设置当前活动索引为被点击的导航项索引
     */
    onClick(item, index) {
      this.activeIndex = index;
      const top = document.getElementById(item.id)?.offsetTop;
      window.scrollTo({ top, behavior: 'smooth' });
    },

    /**
     * 根据滚动位置更新活动索引
     * @param {number} scrollTop - 当前滚动位置
     * 根据滚动位置查找并更新当前的活动导航索引
     */
    updateActiveIndexBasedOnScroll(scrollTop) {
      const offsetTopArr = this.navList.map(item => {
        const element = document.getElementById(item.id);
        return element ? element.offsetTop - 180 : 0; // 计算导航项相对于页面顶部的偏移，未找到则为0
      }).filter(offset => offset > 0); // 过滤掉位于页面顶部之前的导航项

      let navIndex = offsetTopArr.findIndex((offsetTop, i) => scrollTop < offsetTop) - 1;
      if (navIndex === -2) navIndex = offsetTopArr.length - 1; // 处理滚动位置超过最后一个导航项的情况
      this.activeIndex = Math.max(navIndex, 0); // 确保活动索引不为负数
    }
  }
};
</script>

<style scoped lang="scss">
.nav-bar-wrapper {
  height: 64px;
  width: 100%;
}
.flex-start,
.flex-space-between {
  display: flex;
  justify-content: flex-start; // Default for flex-start
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.content-box {
  width: 1200px;
  margin: 0 auto;
}
.nav-bar {
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px #f1f1f2;
  &.is-active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}
.nav-item {
  cursor: pointer;
  position: relative;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  // font-family: 'PingFang-Medium'!important;
  color: #333333;
  &.is-active {
    color: #2469f1;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #2469f1;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
