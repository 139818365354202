<!-- 加入我们 -->
<template>
  <div class="home-ours">
    <div class="home-ours-content">
      <!-- 标题 -->
      <div class="home-ours-title">合作 开放 共赢</div>
      <!-- 按钮组 -->
      <div class="home-ours-btn-group">
        <div class="home-ours-btn" @click="go('/about/recruitment')">
          <div>加入我们的团队</div>
        </div>
        <div class="home-ours-btn" @click="go('/cooperation')">
          <div>成为我们的伙伴</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeOurs',
  methods: {
    go(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style lang='scss' scoped>
.home-ours {
  width: 100%;
  height: 301px;
  background-image: url(~@/assets/img/home-ours.png);
  background-size: cover;
  background-position: 50% 50%;
  .home-ours-content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 71px 0 107px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .home-ours-title {
      height: 40px;
      line-height: 40px;
      font-size: 28px;
      // font-family: 'PingFang-Medium'!important;
      color: #ffffff;
    }
    .home-ours-btn-group {
      display: flex;
      .home-ours-btn {
        cursor: pointer;
        user-select: none;
        width: 154px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 18px;
        background: #ffffff;
        border-radius: 8px;
        color: #09273a;
        margin-right: 18px;
        // font-family: 'PingFang-Medium'!important;
        &:hover {
          box-shadow: 0px 8px 14px 0px rgba(39,99,229,0.53);
        }
      }
    }
  }
}
</style>
