<!-- 方案介绍 -->
<template>
  <div class="home-introduce">
    <!-- 标题 -->
    <div class="home-introduce-title">
      <span>方案介绍</span>
    </div>
    <!-- 内容列表 -->
    <div class="home-introduce-content">
      <div
        v-for="(item, index) in introduceList"
        :key="index"
        class="home-introduce-content-item"
        :style="{
          backgroundImage: `url(${item.img})`,
          backgroundSize: '100% 100%'
        }"
      >
        <div class="home-introduce-content-item-title">
          <span>{{ item.title }}</span>
        </div>
        <div class="home-introduce-content-item-tip">
          <span>{{ item.tip }}</span>
        </div>
        <!-- 查看更多按钮 -->
        <div v-show="item.pathUrl" class="home-introduce-content-item-btn">
          <span @click="go(item.pathUrl)">查看更多</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeIntroduce',
  data() {
    return {
      introduceList: [
        {
          title: '智慧病房解决方案',
          img: require('@/assets/img/introduce1.png'),
          tip: '应用人工智能和物联网技术，优化业务流程，提升医护人员工作效率，改善患者就诊体验。',
          pathUrl: '/smartWard/ward'
        }, {
          title: '智慧护理解决方案',
          img: require('@/assets/img/introduce2.png'),
          tip: '打造的医院护理信息化一体化平台，打破移动护理和护理管理的边界。',
          pathUrl: null
        }, {
          title: '智慧政务解决方案',
          img: require('@/assets/img/introduce3.png'),
          tip: '应用物联网和区块链技术，精简和优化办事程序，提高政府数字化治理水平。',
          pathUrl: '/smartGovernment/certificate'
        }
      ]
    };
  },
  methods: {
    go(pathUrl) {
      if (pathUrl) {
        this.$router.push({
          path: pathUrl
        });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.home-introduce {
  width: 100%;
  height: 727px;
  background: #f2f6fa;
  padding: 76px 0 99px 0;
  .home-introduce-title {
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    // font-family: 'PingFang-Medium'!important;
    text-align: center;
    color: #333333;
    margin-bottom: 50px;
  }
  .home-introduce-content {
    display: flex;
    justify-content: space-between;
    width: 1100px;
    margin: 0 auto;
    .home-introduce-content-item {
      position: relative;
      width: 322px;
      height: 462px;
      border-radius: 8px;
      padding: 39px 19px 0 22px;
      transform: scale(1);
      transition: all .3s;
      &:hover {
        transform: scale(1.1);
        .home-introduce-content-item-btn {
          display: block;
        }
      }
      .home-introduce-content-item-title {
        height: 28px;
        // font-family: 'PingFang-Medium'!important;
        line-height: 28px;
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      .home-introduce-content-item-tip {
        font-size: 14px;
        line-height: 24px;
        // font-family: 'pingfang-Regular';
        text-align: justify;
        color: #ffffff;
      }
      .home-introduce-content-item-btn {
        cursor: pointer;
        display: none;
        position: absolute;
        left: 22px;
        bottom: 47px;
        width: 111px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 8px;
        color: #ffffff;
        background-color: #2072F3;
        &:hover {
          background-color: #4990FF;
        }
      }
    }
  }
}
</style>
