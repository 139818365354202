import { render, staticRenderFns } from "./ecosystem.vue?vue&type=template&id=7681b3fa&scoped=true&"
import script from "./ecosystem.vue?vue&type=script&lang=js&"
export * from "./ecosystem.vue?vue&type=script&lang=js&"
import style0 from "./ecosystem.vue?vue&type=style&index=0&id=7681b3fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7681b3fa",
  null
  
)

export default component.exports