<!--  -->
<template>
  <!-- 嘉服云生态体系 -->
  <div class="home-ecosystem">
    <div class="home-ecosystem-title">
      <span>嘉服云生态体系</span>
    </div>
    <div class="home-ecosystem-content">
      <!-- 左边 -->
      <div class="home-ecosystem-left">
        <!-- 标题 -->
        <div class="home-ecosystem-left-title">
          <span>嘉服云生态体系</span>
        </div>
        <!-- 内容 -->
        <div class="home-ecosystem-left-content">
          嘉服云生态体系打破业务数据孤岛，加强资源集成、关联共享，提供一个以ABCD(人工智能、区块链、云计算、大数据)为代表的前沿技术赋能行业应用、推动数字经济发展平台。致力于用数字化手段推动民生与健康服务、产业经济与企业服务、政府效能与治理、环境与低碳的发展。
        </div>
        <!-- 标题 -->
        <div class="home-ecosystem-left-title">
          <span>嘉服云服务方式</span>
        </div>
        <!-- 内容 -->
        <div class="home-ecosystem-left-content">
          <!-- 内容项 -->
          <div class="home-ecosystem-left-content-item">
            <img src="@/assets/img/hook.png" alt="">
            <div>赋能行业生态合作伙伴，共同打造泛空间集成管控运维服务和数字化政企民服务两大集成产品体系。</div>
          </div>
          <div class="home-ecosystem-left-content-item">
            <img src="@/assets/img/hook.png" alt="">
            <div>集成的平台能力支持ISV快速交付，使能客户实现业务协同与敏捷创新，助力客户成功数字化转型。</div>
          </div>
        </div>
      </div>
      <!-- 右边 -->
      <div class="home-ecosystem-right">
        <img src="@/assets/img/ecosystem.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeEcosystem'
};
</script>

<style lang='scss' scoped>
.home-ecosystem {
  width: 100%;
  height: 767px;
  background: #f5f7fa;
  padding: 76px 0;
  .home-ecosystem-title {
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    // font-family: 'pingfang-Medium';
    text-align: center;
    color: #333333;
  }
  .home-ecosystem-content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 50px auto 0 auto;
    .home-ecosystem-left {
      width: 485px;
      height: 551px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 9px 0px #eef7ff;
      padding: 75px 28px 0 37px;
      .home-ecosystem-left-title {
        height: 25px;
        line-height: 25px;
        font-size: 18px;
        // font-family: 'PingFang-Medium'!important;
        color: #333333;
        margin-bottom: 14px;
      }
      .home-ecosystem-left-content {
        line-height: 24px;
        font-size: 14px;
        // font-family: 'pingfang-Regular';
        text-align: justify;
        color: #666666;
        margin-bottom: 23px;
        .home-ecosystem-left-content-item {
          display: flex;
          margin-bottom: 10px;
          img {
            width: 14px;
            height: 14px;
            margin-top: 4px;
            margin-right: 15px;
          }
          div {
            font-size: 14px;
            // font-family: 'pingfang-Regular';
            text-align: left;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
    .home-ecosystem-right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 691px;
      height: 551px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 9px 0px #eef7ff;
      img {
        width: 667px;
        height: 378px;
      }
    }
  }
}
</style>
